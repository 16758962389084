import { AnalyticsClient } from './AnalyticsClient';
import ReactGA from 'react-ga4';
import { ApplicationConfig } from '../../config/ApplicationConfig';
import { EventFactory } from './EventFactory';

export class GoogleAnalyticsClient implements AnalyticsClient {
    private readonly serviceId: string;
    private readonly eventFactory: EventFactory;

    constructor(serviceId: string, eventFactory: EventFactory) {
        this.serviceId = serviceId;
        this.eventFactory = eventFactory;
    }

    public initAnalytics(): void {
        const trackingId = ApplicationConfig.googleAnalysisTrackingId;
        if (trackingId) {
            ReactGA.initialize(trackingId);
        } else {
            console.error('Google Analytics tracking ID is not defined in ApplicationConfig.');
        }
    }

    public sendCustomerClickedRecommendedLinkEvent(recommendedLink: string): void {
        const clickEvent = this.eventFactory.createCustomerClickRecommendedLinkEvent(recommendedLink);

        ReactGA.gtag('event', 'Button Click', {
            category: clickEvent.category,
            action: clickEvent.action,
            label: clickEvent.clickHref,
            value: 1,
            serviceId: this.serviceId,
        });
    }

    public sendCustomerStartsConversation(location: string): void {
        ReactGA.gtag('event', 'Button Click', {
            category: 'Customer',
            action: 'StartsConversation',
            label: location,
            value: 1,
            serviceId: this.serviceId,
        });
    }

    public sendCustomerOpenedChatBot(pathName: string, open: boolean): void {
        ReactGA.gtag('event', 'Button Click', {
            category: 'Chatbot',
            action: 'Button Click',
            label: open ? 'Close Chatbot' : 'Open Chatbot',
            value: 1,
            serviceId: this.serviceId,
        });
    }
}
