import { ClientFactory } from '../client/ClientFactory';
import { Message } from '../model/message/Message';
import { useContext } from 'react';
import { ChatMessageContext } from '../context/ChatMessageContext';
import { useCustomer } from './useCustomer';
import { CUSTOMER_KEY } from '../model/Conversation';
import { IdentifiersContext } from '../context/Identifiers.context';
import { ActionType, SetCustomerIdAction } from '../model/ActionTypes';

export function useChat() {
    const { serviceId, applicationId } = useContext(IdentifiersContext);
    const { loadCustomerInfo, updateCustomerInfo } = useCustomer(serviceId);
    const streamChatSessionId = `${CUSTOMER_KEY}-${serviceId}`;
    const chatClient = ClientFactory.createChatClient();
    const facadeServiceClient = ClientFactory.createFacadeServiceClient();
    const { messages, pushMessage, waitingForResponse, setWaitingForResponse } = useContext(ChatMessageContext);
    const actionMessageClient = ClientFactory.createActionMessageClient();

    const connect = async () => {
        const guestToken = await facadeServiceClient.getGuestToken(streamChatSessionId);

        await chatClient.connect(streamChatSessionId, guestToken);
    };

    const initiateNewConversation = async () => {
        await connect();

        const customerInfo = await loadCustomerInfo();
        const conversationId = await facadeServiceClient.createConversation(serviceId, applicationId, customerInfo.id);
        await updateCustomerInfo({ ...customerInfo, latestConversationId: conversationId });
        await chatClient.addMessageListener(conversationId, (message) => {
            pushMessage(message);
        });

        await chatClient.addWaitingForResponseListener(conversationId, (waitingForResponse) => setWaitingForResponse(waitingForResponse));
    };

    const sendMessage = async (message: Message) => {
        let customerInfo = await loadCustomerInfo();
        if (!customerInfo.latestConversationId) {
            await initiateNewConversation();
            customerInfo = await loadCustomerInfo();
        }

        await chatClient.sendMessage(streamChatSessionId!, {
            serviceId: serviceId,
            applicationId: applicationId,
            conversationId: customerInfo.latestConversationId!,
            userMessageBody: message,
        });
    };

    return {
        messages: messages,
        pushMessage: pushMessage,
        sendMessage: sendMessage,
        waitingForResponse: waitingForResponse,
    };
}
